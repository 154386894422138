.exploreRemoteJOb {
  font-size: 16px;
  font-weight: 400;
  color: var(--neutral-color-text-05515151, var(--neutral-color-text-05515151, #515151));
  text-align: center;
}

.remoteTabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 16px;

  .remoteTabInnerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    background: #f4f5f6;
    border-radius: 12px;
  }

  .remoteTabItem {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 72px;
    padding: 10px 40px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: #121212;
    text-overflow: ellipsis;
    white-space: pre;
    cursor: pointer;
    border-radius: 12px;

    &[data-checked='true'] {
      font-size: 18px;
      font-weight: 500;
      color: #f5f7fb;
      background-color: #1c70ee;
    }

    .tabInnerWrapper {
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media screen and (max-width: 540px) {
  .remoteTabs {
    font-size: 14px;

    .remoteTabInnerWrapper {
      // background: none;
      // border-radius: 0;
      // gap: 12px;
    }

    .remoteTabItem {
      padding: 7px 16px;
      font-size: 14px;
      line-height: 22px;

      &[data-checked='true'] {
        font-size: 14px;

        // font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

.jobListForRemoteHoemPage {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  padding: 2px;
  overflow: hidden;
}

@media screen and (max-width: 540px) {
  .jobListForRemoteHoemPage {
    padding: 2px;
  }
}

.seeMoreRemoteJobsBtn {
  display: inline-flex;
  gap: 6px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--brand-color-061-b-66-c-9, #1b66c9);

  svg {
    transition: all 0.25s;
    transform: translate3d(0, 0, 0);
  }

  &:hover {
    svg {
      transform: translate3d(5px, 0, 0);
    }
  }
}

.mobileTitle {
  padding: 0 20px 20px 32px;
  margin-bottom: 20px;
}

.mobileTitleText {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: #111212;
}

.mobileSubTitleText {
  margin-top: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #3b3c3c;
}
