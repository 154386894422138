.userExperienceRoot {
  width: 100%;
  margin: 0 auto;
  padding: 100px 0;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 850px) {
  .userExperienceRoot {
    padding: 80px 0;
    &::before {
      display: none;
    }
  }
}
.userExperienceInnerRoot {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1136px;
  gap: 40px;
  position: relative;
}

.embla {
  overflow: hidden;
}

.embla__container {
  display: flex;
}

.CarouselCard {
  padding: 20px 28px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  min-height: 240px;
  border-radius: 8px;

  background: var(--black-000-ffffff, #fff);
  border-radius: 8px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);

  .footer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: #aaa;

    .leftPeopleWrapper {
      display: flex;
      gap: 4px;
    }

    .nameWRapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .name {
      color: var(--neutral-color-text-06121212, var(--neutral-color-text-06121212, #121212));
      font-size: 14px;
      font-weight: 400;
    }

    .rightWrapper {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
  }
  .comments {
    margin-top: 8px;
    color: #515151;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    // word-break: break-all;
    -webkit-box-orient: vertical;
    font-size: 16px;
  }
}

.userRate {
  font-size: 12px;
  :global {
    .ant-rate-star {
      margin-right: 0px !important;
    }
  }
}

.userCommentsViewMoreBtnWrapper {
  text-align: center;
  color: var(--brand-color-061-b-66-c-9, #1b66c9);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  .viewMoreBtn {
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }
  .downIconWrapper {
    border-radius: 50%;
    display: flex;
    width: 16px;
    height: 16px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    border: 1px solid #e8e8ea;
  }
}

.userCOmmentsOnMobile {
  display: flex;
  flex-direction: column;
  gap: 24px;
  // padding: 0 16px;
}
