.embla {
  --slide-size: 33.333%;
  position: relative;
}

@media screen and (max-width: 1000px) {
  .embla {
    --slide-size: 50%;
    // position: relative;
  }
}

@media screen and (max-width: 600px) {
  .embla {
    --slide-size: 90%;
    // position: relative;
  }
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  padding: 20px 0;
  // gap: 12px;
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding: 0 16px;
}

.embla__button {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.833px solid var(--neutral-color-line-02-e-8-e-8-ea, #e8e8ea);
  background: #fff;
  cursor: pointer;

  &[data-isright="true"] {
    svg {
      transform: rotate(180deg);
    }
  }
}

.emblaBtn {
  position: absolute;
  top: 50%;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 38.333px;
  border: 0.833px solid var(--neutral-color-line-02-e-8-e-8-ea, #e8e8ea);
  background: var(--brand-color-01-f-5-f-7-fb, #fff);
  &:hover {
    cursor: pointer;
    background-color: #f5f7fb;
  }
}

@media screen and (max-width: 600px) {
  .emblaBtn {
    display: none;
  }
}
